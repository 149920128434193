<template>
  <v-container fluid 
    class="store-infomation mb-6"
    :class="{'m-store-infomation': $vuetify.breakpoint.smAndDown}"
    >
    <v-col cols="12" 
      class="pa-0 mb-md-4 mt-4 d-flex justify-start "
      :class="{'m-size justify-center ml-auto mr-auto' : $vuetify.breakpoint.smAndDown}"  
    >
      <v-col cols="12" class="pa-0">
        <div 
          class="text-box text-md-h4 text-h5 font-weight-bold"
          :class="{'m-text-box mb-2' : $vuetify.breakpoint.smAndDown}"
        >매장정보</div>
      </v-col>
    </v-col>
    <div 
      class="text-box d-flex justify-center"
      :class="{'m-text-box': $vuetify.breakpoint.smAndDown}"
      >
      <v-tabs
        v-model="tab"
        align-with-title
        light
        color="black"
        height="60"
        class="pa-0"
      >
        <v-tabs-slider color="black"></v-tabs-slider>
        <v-tab
          v-for="store in store"
          :key="store"
          class="text-body-1 font-weight-bold"
        >
          {{ store.name }}
        </v-tab>
      </v-tabs>
    </div>
    <v-col 
      cols="12" 
      class="d-flex justify-center mt-md-6"
      :class="{'m-text-box mt-2' : $vuetify.breakpoint.smAndDown}"  
    >
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="store in store"
          :key="store"
        >
          <v-img 
            :src="require(`@/assets/images/${store.image}.jpg`)"
            max-height="400"
            cover
          >
            <v-col class="pl-4 font-weight-bold white--text">
              (본사직영) {{store.name}} 전경
            </v-col>
          </v-img>
          <v-col class="pa-0 pt-4 pt-md-6 mt-md-4 text-h6 text-md-h4 font-weight-bold">월 사용료 <span class="text-body-1 text-md-h5 pl-4">{{store.price.deposit}}만원/{{store.price.monthlyRent}}만원 부터~</span></v-col> 
          <v-col class="pa-0 pt-4 pt-md-6 mt-md-2 text-h6 text-md-h4 font-weight-bold">면적 <span class="text-body-1 text-md-h5 pl-4">{{store.extent}}m2 ({{store.rooms}}개 독립호실) 2층 (지상주차장 연결 통로)</span></v-col> 
          <v-col class="pa-0 pt-4 pt-md-6 mt-md-2 text-h6 text-md-h4 font-weight-bold">주소 <span class="text-body-1 text-md-h5 pl-4">{{store.address}}</span></v-col> 
          <v-col class="pa-0 pt-4 pt-md-6 mt-md-2 text-h6 text-md-h4 font-weight-bold">추천업종 <span class="text-body-1 text-md-h5 pl-4">모든 요식업분야 1등급(상권분석시스템)</span></v-col> 
          <v-col class="pa-0 pt-4 pt-md-6 mt-md-2 text-h6 text-md-h4 font-weight-bold">옵션 <span class="text-body-1 text-md-h5 pl-4">{{store.option.full}}, {{store.option.default}}</span></v-col> 
          <v-col class="pa-0 pt-4 pt-md-6 mb-4 mb-md-4 font-weight-bold"><span class="text-body-1 text-md-h5">{{store.option.detail}}</span></v-col> 
          <v-img 
            :src="require(`@/assets/images/${store.floorPlan}.png`)"
            max-height="400"
            cover
          >
          </v-img>
          <v-col 
            cols="12" 
            class="d-flex justify-center mt-4 pa-0"
            :class="{'m-text-box' : $vuetify.breakpoint.smAndDown}"
          >
            <div>
              <v-btn
                :width="$vuetify.breakpoint.mdAndUp ? 160 : 130"
                :height="$vuetify.breakpoint.mdAndUp ? 48 : 48"
                outlined
                color="primary-red"
                elevation="0"
                class="round_box text-h7 font-weight-bold  ma-auto"
                :class="{'mt-4' : $vuetify.breakpoint.smAndDown}"
                @click="() => $router.push('/entering')"
              >입점신청
              </v-btn>
              <v-btn
                :width="$vuetify.breakpoint.mdAndUp ? 160 : 130"
                :height="$vuetify.breakpoint.mdAndUp ? 48 : 48"
                outlined
                color="primary-blue"
                elevation="0"
                class="round_box text-h7 font-weight-bold  ma-auto ml-md-4"
                :class="{'mt-4 ml-4' : $vuetify.breakpoint.smAndDown}"
                @click="() => $router.push('/entering')"
              >프랜차이즈 문의
              </v-btn>
            </div>
          </v-col>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-container>
</template>
<script>
export default {
  components: {

  },
  name: 'StoreInfomation',
  methods: {
  },
  data() {
    return {
      tab: null,
      store : [
        {
          id: 0,
          name: '아크키친 A지점',
          image: 'store_a',
          price: {
            monthlyRent:'35',
            deposit: '350',
          },
          extent: '281.39',
          address: '전라북도 전주시 완산구 효자로 161, 204호(웨스트빌 오피스텔)',
          rooms: '11',
          option: {
            default: '기본옵션(주방 설비 완료)',
            full: '풀옵션(몸만와서 창업)',
            detail: '공용 공조시스템, 공용인터넷, 공용cctv, 개별전기, 개별가스, 전문공용관리, 남여 독립화장실, 주차장, 엘레베이터 등', 
          },
          floorPlan: 'a-room'
        },
        {
          id: 1,
          name: '아크키친 B지점',
          image: 'store_b',
          price: {
            monthlyRent:'33',
            deposit: '330',
          },
          extent: '246.95',
          address: '전라북도 전주시 완산구 효자로 161, 206호(웨스트빌 오피스텔)',
          rooms: '9',
          option: {
            default: '기본옵션(주방 설비 완료)',
            full: '풀옵션(몸만와서 창업)',
            detail: '공용 공조시스템, 공용인터넷, 공용cctv, 개별전기, 개별가스, 전문공용관리, 남여 독립화장실, 주차장, 엘레베이터 등', 
          },
          floorPlan: 'b-room'
        }
      ],
    }
  },
  computed: {

  }
}
</script>
<style lang="scss" scoped>
.store-infomation {
  padding-top: 110px; 
  max-width: 768px; 
} 
.m-store-infomation {
  padding-top: 56px;
}
.v-tab {
  margin-left: 0 !important;
}
.text-box {
  width: 744px !important;
  padding-right: 12px !important;
  padding-left: 12px !important;
}
.m-text-box {
  width: 300px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.m-size {
  width: 300px !important;
}
</style>